.footer {
  position: relative;
  padding: $gutter;
  background: $black;
  color: $white;

  @include media($m-screen-up) {
    padding: calc(#{$gutter} * 2);
    min-height: rem(350);
  }
}

.footer__container {
  @include wrapper;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: calc($gutter * 2);
  max-width: $max-width-wide;

  @include media($m-screen-up) {
    flex-direction: row;
  }
}

.footer__logos {
  display: flex;
  gap: calc($gutter * 2);
  text-align: center;

  @include media($m-screen-up) {
    justify-content: flex-start;
    width: 50%;
  }

  @include media($xl-screen-up) {
    gap: calc($gutter * 4);
  }
}

.footer__logo p {
  padding-bottom: $gutter;
  font-size: rem(16);

  @include media($l-screen-up) {
    padding-bottom: calc(#{$gutter} * 2);
    font-size: rem(18);
  }
}

.footer__logo img {
  width: rem(85);

  @include media($m-screen-up) {
    width: rem(150);
  }
}

.footer__legal {
  @include media($m-screen-up) {
    max-width: 25ch;
  }

  @include media($l-screen-up) {
    max-width: 55ch;
  }

  p {
    font-size: rem(12);
    padding-bottom: $gutter;
    
    @include media($m-screen-up) {
      padding-bottom: $gutter;
    }

    @include media($l-screen-up) {
      font-size: rem(16);
    }

    &:last-child {
      padding: 0;
    }
  }

  &--link {
    text-decoration: underline;
  }
}
