.close {
  width: rem(45);
  height: rem(45);
  stroke: $offWhite;
  transition: 0.3s ease;
  border-radius: 50%;

  @include media($m-screen-up) {
    width: rem(55);
    height: rem(55);
  }

  &:hover {
    stroke: $yellow;
    background-color: $orange;
  }

  svg {
    path {
      vector-effect: non-scaling-stroke;
    }
  }
}

.close svg {
  stroke-width: 2px;
}
