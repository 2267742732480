.error {
  position: relative;
  padding-top: rem(75);
  text-align: center;
  background-color: $purple;
  min-height: 70vh;

  @include media($m-screen-up) {
    max-height: 100vh;
  }
  .no-js & {
    padding-top: 0;
  }
}

.error__container {
  @include wrapper;
}

.error__box {
  max-width: $max-width;
  background-color: $yellow;
  border: 2px solid $orange;
  border-radius: $border-radius;
  margin: calc($gutter * 6) 0;
  padding: calc(#{$gutter} * 2);

  @include media($m-screen-up) {
    padding: calc(#{$gutter} * 3);
  }
}

.error__copy {
  p {
    font-size: rem(40);
    font-family: $font-heading;
    padding-bottom: $gutter;

    @include media($m-screen-up) {
      font-size: rem(50);
    }
  }
}

.error__link {
  font-size: rem(40);
  font-family: $font-heading;
  text-decoration: underline;
  
  @include media($m-screen-up) {
    font-size: rem(50);
  }
}