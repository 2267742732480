.bg {
  .no-js & {
    background-image: url(assets/images/swirl-black.svg);
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media (prefers-reduced-motion) {
    background-image: url(assets/images/swirl-black.svg);
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.bg-texture {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: $lightGrey;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.2s ease-out;

  canvas {
    display: block;
  }

  &.rendered {
    opacity: 1;
  }

  .no-js & {
    opacity: 1;
  }

  @media (prefers-reduced-motion) {
    canvas {
      display: none;
    }
  }
}

@media (prefers-reduced-motion) {
  .bg {
    background-image: url(assets/images/swirl-grey.svg);
    background-repeat: no-repeat;
    background-size: cover;
  }

  .bg-texture canvas {
    display: none !important;
  }
}
