.burger-menu__trigger {
  display: none;
}

.burger-menu__bar,
.burger-menu__bar::before,
.burger-menu__bar::after {
  display: block;
  width: rem(37);
  height: rem(2);
  background: $black;
  position: absolute;
  border-radius: rem(4);
  transition: transform 350ms ease-in-out;
}

.burger-menu__bar::before,
.burger-menu__bar::after {
  content: '';
}

.burger-menu__bar::before {
  top: rem(-11);
}

.burger-menu__bar::after {
  bottom: rem(-11);
}

.burger-menu[enabled='true'] .burger-menu__trigger {
  display: block;
  width: 3.5rem;
  height: 3.5rem;
  position: relative;
  z-index: 1;
  background: transparent;
  border: none;
  cursor: pointer;
}

.burger-menu[enabled='true'] .burger-menu__panel {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5rem 1.5rem 2rem 1.5rem;
  width: 100%;
  height: 100vh;
  visibility: hidden;
  opacity: 0;
  background: $yellow;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.burger-menu[enabled='true'] .navigation ul {
  display: block;
}

.burger-menu[enabled='true'] .navigation ul > * + * {
  margin-top: 2rem;
}

.burger-menu[enabled='true'][status='open'] .burger-menu__panel {
  visibility: visible;
  opacity: 1;
  transition: opacity 300ms ease;
}

.burger-menu[enabled='true'][status='closed'] .burger-menu__panel > * {
  opacity: 0;
  transform: translateY(5rem);
}

.burger-menu[enabled='true'][status='open'] .burger-menu__panel > * {
  transform: translateY(0);
  opacity: 1;
  transition: transform 300ms cubic-bezier(0.17, 0.67, 0, 0.87) 300ms, opacity 300ms ease
    400ms;
}

.burger-menu[enabled='true'][status='open'] .burger-menu__bar::before {
  top: 0;
  transform: rotate(45deg);
}

.burger-menu[enabled='true'][status='open'] .burger-menu__bar::after {
  top: 0;
  transform: rotate(-45deg);
}

.burger-menu[enabled='true'][status='open'] .burger-menu__bar {
  background: transparent;
  border-color: transparent;
  transform: rotate(180deg);
}
