.about-image {
  background-image: url(assets/images/swirl-red-grey.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.about-image__container {
  @include wrapper;
  max-width: $max-width-wide;
}

.about-image__image {
  position: relative;
  max-width: rem(1000);
  margin: rem(50) auto;
  @include media($m-screen-up) {
    margin: rem(100) auto;
  }
  img {
    width: 100%;
    border: rem(2) solid $yellow;
    border-radius: $border-radius;
  }
}

.about-image__text {
  display: none;
  @include media($m-screen-up) {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    color: $yellow;
    z-index: 1;
    font-size: rem(14);
    padding: $gutter;
    padding: calc($gutter * 2);
    font-size: rem(18);
    max-width: 56ch;
  }
}

.about-image__caption {
  background-color: $yellow;
  margin-top: rem(-50);
  padding: calc($gutter * 3) $gutter $gutter $gutter;
  border: 2px solid $white;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  @include media($m-screen-up) {
    display: none;
  }
}