.button {
  position: relative;
  padding: rem(6) rem(20) rem(6) rem(30);
  border: 2px solid $black;
  border-radius: $border-radius;
  font-family: $font-heading;
  font-size: rem(25);
  color: $black;
  
  @include media($m-screen-up) {
    font-size: rem(30);
  }
}