.question {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: calc(#{$gutter} * 1) calc(#{$gutter} * 1) calc(#{$gutter} * 1.75)
    calc(#{$gutter} * 1);

  @include media($s-screen-up) {
    max-width: 85%;
  }

  @include media($m-screen-up) {
    padding: calc(#{$gutter} * 2) calc(1.25rem * 4.5) calc(#{$gutter} * 2)
      calc(#{$gutter} * 2);
    text-align: left;
  }

  @include media($l-screen-up) {
    padding: calc(#{$gutter} * 2) calc(1.25rem * 5) calc(#{$gutter} * 2)
      calc(#{$gutter} * 2);
  }

  @include media($l-screen-up) {
    max-width: 75%;
  }

  &--max-width {
    max-width: none;
  }

  &--margin-top {
    margin-top: calc($gutter * 2.5);
    @include media($m-screen-up) {
      margin-top: rem(25);
    }
  }
}

p.question__text {
  font-size: rem(16);
  width: 100%;
  @include media($m-screen-up) {
    font-size: rem(25);
  }
}

.question:nth-child(odd) {
  align-self: flex-end;
}

.question__arrow {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: calc(#{$gutter} * 0.25);

  @include media($m-screen-up) {
    top: 50%;
    left: auto;
    right: 0;
    transform: translateY(-50%);
    margin-right: calc(1.25rem * 1);
  }
}

.question:nth-child(3n + 1) {
  @include backgroundPurpleQuestion;
  .question__drip svg {
    fill: $purple;
    stroke: $yellow;
  }

  .question__arrow.arrow {
    border-color: $yellow;
    svg {
      stroke: $yellow;
      transition: stroke 0.3s ease;
    }
  }

  &:hover .question__arrow.arrow {
    background-color: $yellow;
    svg {
      stroke: $purple;
    }
  }
}

.question:nth-child(3n + 2) {
  @include backgroundOrangeQuestion;
  .question__drip svg {
    fill: $orange;
    stroke: $yellow;
  }

  .question__arrow.arrow {
    border-color: $yellow;
    svg {
      stroke: $yellow;
      transition: stroke 0.3s ease;
    }
  }

  &:hover .question__arrow.arrow {
    background-color: $yellow;
    svg {
      stroke: $orange;
    }
  }
}

.question:nth-child(3n + 3) {
  @include backgroundYellowQuestion;

  .question__drip svg {
    fill: $yellow;
    stroke: $orange;
  }

  .question__arrow.arrow {
    border-color: $orange;
    svg {
      stroke: $orange;
      transition: stroke 0.3s ease;
    }
  }

  &:hover .question__arrow.arrow {
    background-color: $orange;
    svg {
      stroke: $yellow;
    }
  }
}

// These are the dimensions of the SVG
$dripWidthSml: 20.4;
$dripHeightSml: 23;
$dripWidth: 27.2;
$dripHeight: 31;

// Drips
.question__drip {
  position: absolute;
  height: rem($dripWidthSml);
  width: rem($dripHeightSml);
  bottom: -1.2rem;
  right: -1.3rem;

  @include media($m-screen-up) {
    height: rem($dripWidth);
    width: rem($dripHeight);
    bottom: rem(-25);
    right: rem(-25);
  }

  svg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    path {
      vector-effect: non-scaling-stroke;
    }
  }
}

.question:nth-child(even) .question__drip {
  left: -1.3rem;
  transform: scaleX(-1);
  @include media($m-screen-up) {
    left: rem(-25);
  }
}
