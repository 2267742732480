.arrow {
  display: inline-block;
  border-radius: 50%;
  stroke: $orange;
  width: rem(20);
  height: rem(20);

  @include media($m-screen-up) {
    width: rem(40);
    height: rem(40);
  }

  &--large {
    width: rem(30);
    height: rem(30);

    @include media($m-screen-up) {
      width: rem(60);
      height: rem(60);
    }
  }

  &--circle {
    @include media($m-screen-up) {
      border: 2px solid $black;
      border-radius: 50%;
    }
  }

  &--orange-circle {
    border: 2px solid $orange;
    border-radius: 50%;
  }

  svg {
    path,
    line {
      vector-effect: non-scaling-stroke;
    }
  }
}
