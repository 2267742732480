.hero {
  position: relative;
  padding-top: rem(75);
  text-align: center;

  .no-js & {
    padding-top: 0;
  }
}

.hero__container {
  @include wrapper;
}

.hero__content {
  padding-top: calc($gutter * 2);
}

.hero__image {
  max-width: rem(900);
  margin: 0 auto;
  @include media($m-screen-up) {
    padding: 0 calc($gutter * 2) 0 $gutter;
  }

  &-desktop {
    display: none;
      @include media($m-screen-up) {
        display: block;
      }
    }
    
  &-mobile {
    @include media($m-screen-up) {
      display: none;
    }
  }
}

.hero__image h1 svg {
  .no-js & {
    fill: $white;
  }
}

.hero__text {
  padding-top: rem(30);
  text-wrap: pretty;

  @include media($m-screen-up) {
    padding: calc($gutter * 2) 0 0;
  }

  p {
    font-family: $font-heading;
    font-size: rem(22);
    @include media($s-screen-up) {
      font-size: rem(25);
    }
    @include media($m-screen-up) {
      font-size: rem(36);
    }
  }

  .no-js & {
    color: $white;
  }
}
