.skip-link {
  display: inline-block;
  position: absolute;
  top: 1rem;
  left: 1rem;
  background: $black;
  border: 2px solid $black;
  color: $white;
  border-radius: $border-radius;
  padding: 0.7rem 1rem 0.5rem 1rem;
  z-index: 3;
}

.skip-link:hover {
  background: $white;
  color: $black;
}

.skip-link:not(:focus) {
  position: absolute;
  border: 0;
  height: auto;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 0;
  white-space: nowrap;
}