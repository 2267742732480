*,
:before,
:after {
  box-sizing: border-box;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

body {
  position: relative;
  font-size: rem(18);
  min-height: 100%;
  font-family: $font-body;

  &.modal-is-active {
    overflow: hidden;
  }
}

main {
  position: relative;
}

a {
  color: currentColor;
  text-decoration: none;
  cursor: pointer;
}

img {
  max-width: 100%;
  height: auto;
}

button {
  cursor: pointer;
}

li {
  list-style: none;
}
