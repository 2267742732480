.answers__container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.answers__question {
  @include grid;
  position: relative;
}

.answers__question-item {
  position: relative;
  grid-column: 1 / span 13;
  max-width: 90%;
  @include backgroundPurpleQuestion;
  padding: $gutter;

  @include media($s-screen-up) {
    padding: calc(#{$gutter} * 1);
  }

  @include media($m-screen-up) {
    padding: calc(#{$gutter} * 2);
    max-width: 85%;
  }

  @include media($l-screen-up) {
    padding: calc(#{$gutter} * 2);
  }

  @include media($l-screen-up) {
    max-width: 75%;
  }

  &--max-width {
    max-width: none;
  }

  &--margin-top {
    margin-top: calc($gutter * 2);
    @include media($m-screen-up) {
      margin-top: calc($gutter * 3.5);
    }
    @include media($l-screen-up) {
      margin-top: rem(25);
    }
  }

  .question__drip {
    right: rem(-16);
    fill: $purple;
    stroke: $yellow;
    @include media($m-screen-up) {
      right: rem(-25);
    }
  }

  .question__drip svg {
    fill: $purple;
    stroke: $yellow;
  }
}

// These are the dimensions of the SVG
$dripWidthSml: 20.4;
$dripHeightSml: 23;
$dripWidth: 27.2;
$dripHeight: 31;

.answers__list {
  ul {
    @include listReset;
    @include grid;
    padding-top: $gutter;
  }

  li {
    position: relative;
    grid-column: span 7;
    grid-column: 5 / span 12;
    @include backgroundYellowQuestion;
    width: 100%;
    padding: $gutter;
    margin-top: $gutter;
    word-break: break-word;
    font-size: rem(14);
    line-height: 1.3;
    @include media($s-screen-up) {
      font-size: rem(16);
    }
    @include media($m-screen-up) {
      grid-column: 4 / span 12;
      padding: calc(#{$gutter} * 1.5);
      margin-top: calc($gutter * 2);
      font-size: rem(25);
    }

    &::after {
      content: '';
      background-image: url('/src/assets/images/drip-yellow.svg');
      background-repeat: no-repeat;
      position: absolute;
      height: rem($dripWidthSml);
      width: rem($dripHeightSml);
      left: -1.3rem;
      bottom: -1.2rem;
      @include media($m-screen-up) {
        height: rem($dripWidth);
        width: rem($dripHeight);
        left: rem(-25);
        bottom: rem(-25);
      }
    }
  }
}

.answers__list li {
  &:nth-child(6n + 1):before {
    content: '';
    position: absolute;
    background-image: url('/src/assets/images/avatar_1.svg');
    background-repeat: no-repeat;
    height: rem(64);
    width: rem(64);
    top: rem(-5);
    left: rem(-90);
    @include media($m-screen-up) {
      height: rem(90);
      width: rem(90);
      left: rem(-130);
    }
  }

  &:nth-child(6n + 2):before {
    content: '';
    position: absolute;
    background-image: url('/src/assets/images/avatar_2.svg');
    background-repeat: no-repeat;
    height: rem(64);
    width: rem(64);
    top: rem(-5);
    left: rem(-90);
    @include media($m-screen-up) {
      height: rem(90);
      width: rem(90);
      left: rem(-130);
    }
  }

  &:nth-child(6n + 3):before {
    content: '';
    position: absolute;
    background-image: url('/src/assets/images/avatar_3.svg');
    background-repeat: no-repeat;
    height: rem(64);
    width: rem(64);
    top: rem(-5);
    left: rem(-90);
    @include media($m-screen-up) {
      height: rem(90);
      width: rem(90);
      left: rem(-130);
    }
  }

  &:nth-child(6n + 4):before {
    content: '';
    position: absolute;
    background-image: url('/src/assets/images/avatar_4.svg');
    background-repeat: no-repeat;
    height: rem(64);
    width: rem(64);
    top: rem(-5);
    left: rem(-90);
    @include media($m-screen-up) {
      height: rem(90);
      width: rem(90);
      left: rem(-130);
    }
  }

  &:nth-child(6n + 5):before {
    content: '';
    position: absolute;
    background-image: url('/src/assets/images/avatar_5.svg');
    background-repeat: no-repeat;
    height: rem(64);
    width: rem(64);
    top: rem(-5);
    left: rem(-90);
    @include media($m-screen-up) {
      height: rem(90);
      width: rem(90);
      left: rem(-130);
    }
  }

  &:nth-child(6n + 6):before {
    content: '';
    position: absolute;
    background-image: url('/src/assets/images/avatar_6.svg');
    background-repeat: no-repeat;
    height: rem(64);
    width: rem(64);
    top: rem(-5);
    left: rem(-90);
    @include media($m-screen-up) {
      height: rem(90);
      width: rem(90);
      left: rem(-130);
    }
  }
}
