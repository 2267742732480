@mixin wrapper($verticalPadding: calc(#{$gutter} * 2)) {
  position: relative;
  margin: 0 auto;
  padding: $gutter;
  max-width: $max-width;

  @include media($s-screen-up) {
    padding: calc(#{$gutter} * 2);
  }
}

@mixin listReset {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin svgPad($width, $height) {
  position: relative;

  &::before {
    display: block;
    content: '';
    width: 100%;
    height: 0;
    padding-bottom: percentage($height/$width);
  }

  svg {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
  }
}

// Colour options
@mixin backgroundPurple {
  background-color: $purple;
  border: 2px solid $yellow;
  border-radius: $border-radius;
}

@mixin backgroundOrange {
  background-color: $orange;
  border: 2px solid $yellow;
  border-radius: $border-radius;
}

@mixin backgroundYellow {
  background-color: $yellow;
  border: 2px solid $orange;
  border-radius: $border-radius;
}

// Questions
@mixin backgroundPurpleQuestion {
  background-color: $purple;
  border: 2px solid $yellow;
  border-radius: $border-radius;
}

@mixin backgroundOrangeQuestion {
  background-color: $orange;
  border: 2px solid $yellow;
  border-radius: $border-radius;  
}

@mixin backgroundYellowQuestion {
  background-color: $yellow;
  border: 2px solid $orange;
  border-radius: $border-radius;
}