.p-xsmall {
  font-size: rem(16);
  @include media($s-screen-up) {
    font-size: rem(18);
  }
}

.p-small {
  line-height: 1.3;
  @include media($m-screen-up) {
    font-size: rem(20);
  }
}

p {
  font-family: $font-body;
  margin: 0;
  line-height: 1.3;
  font-size: rem(16);
  @include media($l-screen-up) {
    font-size: rem(25);
  }
}

.p-medium {
  font-size: rem(25);
  @include media($m-screen-up) {
    font-size: rem(30);
  }
}

.p-large {
  font-size: rem(30);
  @include media($m-screen-up) {
    font-size: rem(36);
  }
}

strong {
  font-weight: bold;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-heading;
  margin: 0;
  line-height: 1;
  font-weight: 200;
}

h1 {
  font-size: rem(60);

  @include media($m-screen-up) {
    font-size: rem(100);
  }
}

h2 {
  font-size: rem(30);
  line-height: 1;

  @include media($m-screen-up) {
    font-size: rem(55);
    line-height: 1.25;
  }
}

h3 {
  font-size: rem(30);
  line-height: 1;

  @include media($m-screen-up) {
    font-size: rem(60);
  }
}

h4 {
  font-size: rem(60);

  @include media($m-screen-up) {
    font-size: rem(100);
  }
}
