.nav {
  color: $black;
  z-index: 2;
  
  @include media($m-screen-up) {
    max-width: $max-width-wide;
    justify-content: space-between;
    align-items: center;
  }
}

.nav__items {
  padding: calc($gutter * 3) 0;

  @include media($m-screen-up) {
    display: flex;
    padding: 0;
  }

  .no-js & {
    padding: 0;
  }
}

.nav__item {
  font-family: $font-heading;
  padding-bottom: $gutter;
  font-size: rem(35);
  width: fit-content;
  
  @include media($s-screen-up) {
    font-size: rem(50);
  }

  @include media($m-screen-up) {
    padding: 0 rem(20);
      font-size: rem(26);
  }

  .is-active::after {
    content: '';
    display: block;
    background-color: black;
    border-radius: 1px;
    height: 2px;
  }
}

.nav__link {
  .page-question-page &,
  .no-js & {
    color: $white;
  }
}

.nav__link:hover {
  color: $orange;
}

.page-about .nav__link:hover {
  @include media($m-screen-up) {
    color: $offWhite;
  }
}

