// Typography
$font-heading: "new-spirit-condensed", serif;
$font-body: 'JokkerMedium';

// Colors
$black: #000000;
$grey: #1B2029;
$purple: #A598FF;
$lilac: #C2B8E0;
$brown: #A46C39;
$orange: #FF732F;
$yellow: #E1FC5E;
$lightGrey: #E9E7EB;
$offWhite: #FFF1E4;
$white: #FFFFFF;

// Spacing
$gutter: rem(20);

// Sizes
$max-width: rem(1000);
$max-width-medium: rem(1200);
$max-width-wide: rem(1600);

//Borders
$border-radius: rem(30);
$blackBorder: 2px solid $black;
$orangeBorder: 2px solid $orange;
