.text-section {
  position: relative;
  background-color: $offWhite;
  text-wrap: balance;

  &--bottom {
    background-color: $purple;
  }
}

.text-section__container {
  @include wrapper;
  text-align: center;
}

.text-section__text {
  margin: calc($gutter * 2) $gutter;

  @include media($m-screen-up) {
    margin: calc(#{$gutter} * 3);
  }
}

.text-section__text p {
  max-width: 55ch;
  margin: auto;
  padding-bottom: $gutter;

  &:last-child {
    padding: 0;
  }
}

.text-section__text--bottom {
  padding-bottom: $gutter;

  @include media($m-screen-up) {
    padding-bottom: calc(#{$gutter} * 2);
  }
}

.text-section__boxed {
  @include backgroundYellow;
  color: $black;
  padding: calc($gutter * 1.75);
  margin: $gutter 0;

  @include media($m-screen-up) {
    margin: calc(#{$gutter} * 2) 0;
  }

  @include media($m-screen-up) {
    padding: calc(#{$gutter} * 3);
  }

  & p {
    font-family: $font-heading;
    font-size: rem(25);
    text-wrap: balance;
    padding-bottom: $gutter;
    line-height: 1.2;

    @include media($m-screen-up) {
      font-size: rem(36);
      line-height:initial;
    }
    &:last-child {
      padding: 0;
    }
  }
}
