.question-page {
  position: relative;
  padding: rem(100) $gutter $gutter $gutter;

  @include media($m-screen-up) {
    padding: calc(#{$gutter} * 3);
  }

  .no-js & {
    padding: rem(50) $gutter $gutter $gutter;
  }
}

.question-page__background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: url(assets/images/swirl-black.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

.question-page__container {
  background-color: rgba($white, 0.85);
  border: $orangeBorder;
  border-radius: $border-radius;
  padding: $gutter;
  max-width: rem(1200);
  margin: auto;

  @include media($l-screen-up) {
    padding: calc(#{$gutter} * 2);
  }
}

.question-page__top {
  margin-bottom: calc(#{$gutter} * 1.5);

  @include media($l-screen-up) {
    @include grid;
    grid-column: span 3;
  }
}

.question-page__close {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  padding: calc($gutter * 0.5);
  background-color: $purple;
  z-index: 1;

  .no-js &,
  .page-question-page & {
    display: none;
  }
}

.question-page__close--content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.question-page__content {
  display: grid;
  grid-template-columns: repeat(12, 1fr) min-content;

  @include media($l-screen-down) {
    grid-row-gap: calc($gutter * 3);
  }
}

.question-page__info {
  grid-column: 1 / span 12;
  grid-row: 2;

  @include media($l-screen-up) {
    grid-row: 1;
    grid-column: 1 / span 4;
    padding-left: $gutter;
  }
}

.question-page__answers {
  grid-column: 1 / span 12;
  grid-row: 1;

  @include media($l-screen-up) {
    grid-column: 6 / span 7;
  }
}

.question-page__button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $purple;
  border-color: $black;
  margin-top: calc($gutter * 2);

  @include media($m-screen-down) {
    font-size: rem(20);
  }

  @include media($xs-screen-down) {
    font-size: rem(16);
  }

  @include media($m-screen-up) {
    width: max-content;
    margin-top: calc($gutter * 4);
  }

  &:hover {
    background-color: $orange;
  }

  &:active {
    color: $yellow;
  }

  .arrow {
    stroke: $yellow;
  }
}
